import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import { Layout, Wrapper, Button, Article } from '../components';
import PageProps from '../models/PageProps';
import Helmet from 'react-helmet';
import config from '../../config/SiteConfig';
import { media } from '../utils/media';
import rgba from 'polished/lib/color/rgba';
import darken from 'polished/lib/color/darken';

const Homepage = styled.main`
  display: flex;
  height: 100vh;
  flex-direction: row;
  background: url(/assets/bg_color.jpg) no-repeat;
  background-size: cover;
  @media ${media.tablet} {
    height: 100%;
    flex-direction: column;
  }
  @media ${media.phone} {
    height: 100%;
    flex-direction: column;
  }
`;

const GridRow: any = styled.div`
  flex: ${(props: any) => (props.background ? 1 : 2)};
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  background: ${(props: any) =>
    props.background
      ? `linear-gradient(
      -185deg,
      ${rgba(darken(0.2, props.theme.colors.modio.light), 0.5)},
      ${rgba(darken(0, props.theme.colors.modio.dark), 1)})`
      : null};
  background-size: cover;
  padding: 2rem 4rem;
  color: ${(props: any) => (props.background ? props.theme.colors.white : null)};
  h1 {
    color: ${(props: any) => (props.background ? props.theme.colors.white : null)};
  }
  a {
    color: ${(props: any) => (props.background ? props.theme.colors.modio.light : null)};
  }
  @media ${media.tablet} {
    padding: 3rem 3rem;
  }
  @media ${media.phone} {
    padding: 2rem 1.5rem;
  }
  .homeLink {
    position: absolute;
    top: 2vw;
    left: 2vw;
  }
  .contactLink {
    position: absolute;
    top: 2vw;
    right: 2vw;
  }
  .homeLink, .contactLink {
    background-color: rgba(0,0,0,0.1);
    padding: 0.5rem;
    border-radius: 50px;
  }
  img.homeLogo {
    width: 2vw;
    display: inline-block;
    padding: 0;
    margin: 0;
    margin-right: 1em;
    min-width: 2rem;
    vertical-align: bottom;
  }
  img.contactLogo {
    width: 2vw;
    display: inline-block;
    padding: 0;
    margin: 0;
    margin-left: 1em;
    min-width: 2rem;
    vertical-align: bottom;
  }
  .senaste {
    font-size: 1rem;
    padding: 0.2rem 0.2rem 0.2rem 2rem;
    margin: 0.2rem 0.2rem 0.2rem -2rem;
    background-color: ${(props: any) => (props.theme.colors.modio.light)};
    transform: rotate(-5deg);
  }
  .textRight {
    transform: rotate(-5deg);
    font-size: 0.7rem;
    a {
      background-color: ${(props: any) => (props.theme.colors.modio.light)};

      color: ${(props: any) => (props.theme.colors.modio.dark)};
      padding: 0.2rem;
    }
  }
`;

const HomepageContent: any = styled.div`
  max-width: 30rem;
  text-align: ${(props: any) => (props.center ? 'center' : 'left')};
`;

export default class IndexPage extends React.Component<PageProps> {
  public render() {
    const { data } = this.props;
    const { edges, totalCount } = data.allMarkdownRemark;
    return (
      <Layout>
        <Wrapper fullWidth={true}>
          <Helmet title={`Start | ${config.siteTitle}`} />
          <Homepage>
            <GridRow>
            <img src={config.siteLogo} id='main_logo'/>
            <a href='/contact' className="contactLink">Kontakt<img src='/assets/contact.svg' alt='Kontakt' className='contactLogo' /></a>

              <HomepageContent center={true}>
                <h1>
                  Modio <br />
                  SmartSolar
                </h1>
                <p>Det trygga och framtidssäkra valet för övervakning av solcellsanläggningar</p>
                <Link to="/installatorer">
                  <Button big={true}>
                    <svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1764 11q33 24 27 64l-256 1536q-5 29-32 45-14 8-31 8-11 0-24-5l-453-185-242 295q-18 23-49 23-13 0-22-4-19-7-30.5-23.5t-11.5-36.5v-349l864-1059-1069 925-395-162q-37-14-40-55-2-40 32-59l1664-960q15-9 32-9 20 0 36 11z" />
                    </svg>
                    Mer information
                  </Button>
                </Link>
              </HomepageContent>
            </GridRow>
            <GridRow background={true}>
              <HomepageContent>
                <h2 className='senaste'>Senaste nytt</h2>
                {edges.map(post => (
                  <Article
                    title={post.node.frontmatter.title}
                    date={post.node.frontmatter.date}
                    excerpt={post.node.excerpt}
                    timeToRead={post.node.timeToRead}
                    slug={post.node.fields.slug}
                    category={post.node.frontmatter.category}
                    key={post.node.fields.slug}
                  />
                ))}
                <p className={'textRight'}>
                  <Link to={'/blog'}>Alla nyheter ({totalCount})</Link>
                </p>
              </HomepageContent>
            </GridRow>
          </Homepage>
        </Wrapper>
      </Layout>
    );
  }
}
export const IndexQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: 1) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "YYYY-MM-DD")
            category
          }
          timeToRead
        }
      }
    }
  }
`;
